import React from 'react'
import Header from '../components/Header'
import HeroComponent from '../components/HeroComponent'
import AboutComponent from '../components/AboutComponent'
import ServiceComponent from '../components/ServiceComponent'
import FooterComponent from '../components/FooterComponent'

const Home = () => {
  return (
    <>
    <Header />
    <HeroComponent />
    <AboutComponent />
    <ServiceComponent />
    <FooterComponent />
    </>
  )
}

export default Home