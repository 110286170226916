import React from 'react'
import ReactLogo from '../assets/images/fatimahospitallogo1.png';
const Header = () => {
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between my-2 mx-md-5 mx-2 flex-column flex-sm-row">
                <h2 className="fs-5 fw-bold text-info w-100">FATIMA Hospital Lahore Road Pindi Bhattian Hafizabad Punjab Pakistan</h2>
                <h4 className="fs-5 fw-bold text-danger text-end w-100 mt-3 mt-sm-0">Phone No: 0300-4854345 <h4 className="fs-5 fw-bold text-danger text-end w-100 mt-3 mt-sm-0">Ptcl No: 0547-531-346</h4></h4>
            </div>
            <nav className="navbar navbar-expand-mds bg-info">
                <div className="container-fluid">
                    <div className="d-flex w-100 align-items-center justify justify-content-space-between flex-column flex-sm-row">
                        <div className='text-left w-100 '>
                            <img src={ReactLogo} style={{ width: 200, }} />
                        </div>
                        <h4 className="fs-5 fw-bold text-success text-end w-100 mx-md-5 mx-0">Reg. No-R-02182</h4>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Header