import React from 'react'
import xrays from '../assets/images/xrays.png'
import vaccines from '../assets/images/vaccines.png'
import img6 from '../assets/images/img6.jpg'
import img7 from '../assets/images/img7.jpg'
const ServiceComponent = () => {
  return (
    <div>

      <h1 className="pt-5 mt-5 mb-0 text-center text-primary fw-bold">SERVICES</h1>

      <div className="d-sm-none">
        <p className="mt-5 mb-5 w-100 px-3 text-center fs-5 text-primary fw-normal">
          At [FATIMA Hospital], we take pride in offering a wide range of comprehensive healthcare services, designed to cater to all your medical needs under one roof.

          Our Ultrasound services are equipped with the latest imaging technology, enabling our skilled radiologists to provide accurate and detailed diagnostic examinations. From routine abdominal and pelvic ultrasounds to specialized vascular and musculoskeletal studies, our team ensures precise and timely results to aid in your diagnosis and treatment.

          Our Outpatient Department (OPD) is staffed with experienced and compassionate doctors, committed to providing personalized medical consultations for various health concerns. Whether you require general check-ups, specialized consultations, or follow-up care, our OPD services are tailored to address your unique health requirements with utmost care and attention.
        </p>
      </div>
      <div className="d-none d-sm-inline-block">
        <p className="mt-5 mb-5 w-50 mx-auto text-center fs-5 text-primary fw-normal">
          At [FATIMA Hospital], we take pride in offering a wide range of comprehensive healthcare services, designed to cater to all your medical needs under one roof.

          Our Ultrasound services are equipped with the latest imaging technology, enabling our skilled radiologists to provide accurate and detailed diagnostic examinations. From routine abdominal and pelvic ultrasounds to specialized vascular and musculoskeletal studies, our team ensures precise and timely results to aid in your diagnosis and treatment.

          Our Outpatient Department (OPD) is staffed with experienced and compassionate doctors, committed to providing personalized medical consultations for various health concerns. Whether you require general check-ups, specialized consultations, or follow-up care, our OPD services are tailored to address your unique health requirements with utmost care and attention.
        </p>
      </div>


      <div className="row align-items-center justify-content-center  g-0 m-0 mx-3 my-5 pt-5">
        <div className="col-12 col-sm-5 col-md-3 col-lg-3 mb-3">
          <div className="card" style={{ width: "90%", margin: "auto" }}>
            {/* <img src={ultrasound} style={{ height: 280 }} className="card-img-top" alt="..." /> */}
            <img src={img6} style={{ height: 280 }} className="card-img-top" alt="..." />
            <div className="card-body p-4">
              <h5 className="card-title fs-4 fw-bold text-primary m-0 text-center">Ultrasound</h5>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-5 col-md-3 col-lg-3 mb-3">
          <div className="card" style={{ width: "90%", margin: "auto" }}>
            <img src={xrays} style={{ height: 280 }} className="card-img-top" alt="..." />
            <div className="card-body p-4">
              <h5 className="card-title fs-4 fw-bold text-primary m-0 text-center">Lab Test</h5>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-5 col-md-3 col-lg-3 mb-3">
          <div className="card" style={{ width: "90%", margin: "auto" }}>
            {/* <img src={health} style={{ height: 280 }} className="card-img-top" alt="..." /> */}
            <img src={img7} style={{ height: 280 }} className="card-img-top" alt="..." />
            <div className="card-body p-4">
              <h5 className="card-title fs-4 fw-bold text-primary m-0 text-center">OPD</h5>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-5 col-md-3 col-lg-3 mb-3">
          <div className="card" style={{ width: "90%", margin: "auto" }}>
            <img src={vaccines} style={{ height: 280 }} className="card-img-top" alt="..." />
            <div className="card-body p-4">
              <h5 className="card-title fs-4 fw-bold text-primary m-0 text-center">Emergency Service</h5>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="my-5 py-5 text-center">
        <button type="button" class="btn btn-success px-5">Read More</button>
      </div> */}
    </div>
  )
}

export default ServiceComponent

