import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import { Helmet } from "react-helmet";
function App() {
  return (
    <>
      <Helmet>
        <title>Fatima Hospital Pindi Bhattian</title>
        <meta name="Fatima_Hospital_Pindi_Bhattian" content="Fatima Hospital Pindi Bhattian District Hafizabad Punjab Pakistan is located on the Lahore road near THQ pindi bhattian" />
      </Helmet>
      <Home />
    </>
  );
}

export default App;
