import React from 'react'
import DrRiaz from '../assets/images/DrRiaz.jpg'
import ladydr from '../assets/images/ladydrig.jpg'
import img1 from '../assets/images/img1.png'
import img2 from '../assets/images/img2.png'
import img3 from '../assets/images/img3.png'
import img4 from '../assets/images/img4.png'
import img5 from '../assets/images/img5.jpg'
import img6 from '../assets/images/img6.jpg'
import img7 from '../assets/images/img7.jpg'
import img8 from '../assets/images/img8.jpg'
import img9 from '../assets/images/img9.jpg'
import img10 from '../assets/images/img10.jpg'
const AboutComponent = () => {
  return (
    <div>
      <h1 className="pt-5 mt-5 mb-0 text-center text-primary fw-bold">ABOUT US</h1>

      <div className="d-sm-none">
        <p className="mt-5 mb-5 w-100 px-3 text-center fs-5 text-primary fw-normal">
          Welcome to [FATIMA Hospital], your premier destination for personalized and compassionate healthcare. Nestled in a serene and state-of-the-art facility, our team of highly skilled physicians, nurses, and support staff work tirelessly to ensure your well-being is our utmost priority.
        </p>
        <p className="mb-5 text-center fs-5 w-100 px-3 text-primary fw-normal">
          At [FATIMA Hospital], we strive to create an environment where every patient feels valued and heard, fostering a sense of trust and confidence in our services. Your health and comfort are our mission.
        </p>
      </div>

      <div className="d-none d-sm-inline-block">
        <p className="mt-5 mb-5 w-50 mx-auto text-center fs-5 text-primary fw-normal">
          Welcome to [FATIMA Hospital], your premier destination for personalized and compassionate healthcare. Nestled in a serene and state-of-the-art facility, our team of highly skilled physicians, nurses, and support staff work tirelessly to ensure your well-being is our utmost priority.
        </p>
        <p className="mb-5 text-center fs-5 w-50 mx-auto text-primary fw-normal">
          At [FATIMA Hospital], we strive to create an environment where every patient feels valued and heard, fostering a sense of trust and confidence in our services. Your health and comfort are our mission.
        </p>
      </div>


      <div className="row align-items-center justify-content-center g-0 m-0 mt-5 pt-5">
        <div className="col-12 col-sm-6 col-md-4 text-center mb-3">
          <img src={img4} style={{ height: 395, width: "95%" }} className="card-img-top" alt="..." />
        </div>
        <div className="col-12 col-sm-6 col-md-4 text-center mb-3">
          <img src={img2} style={{ height: 395, width: "95%" }} className="card-img-top" alt="..." />
        </div>
        <div className="col-12 col-sm-6 col-md-4 text-center mb-3">
          <img src={img6} style={{ height: 395, width: "95%" }} className="card-img-top" alt="..." />
        </div>
        <div className="col-12 col-sm-6 col-md-4 text-center mb-3">
          <img src={img5} style={{ height: 395, width: "95%" }} className="card-img-top" alt="..." />
        </div>
        <div className="col-12 col-sm-6 col-md-4 text-center mb-3">
          <img src={img3} style={{ height: 395, width: "95%" }} className="card-img-top" alt="..." />
        </div>
        <div className="col-12 col-sm-6 col-md-4 text-center mb-3">
          <img src={img7} style={{ height: 395, width: "95%" }} className="card-img-top" alt="..." />
        </div>
        <div className="col-12 col-sm-6 col-md-4 text-center mb-3">
          <img src={img9} style={{ height: 395, width: "95%" }} className="card-img-top" alt="..." />
        </div>
        <div className="col-12 col-sm-6 col-md-4 text-center mb-3">
          <img src={img1} style={{ height: 395, width: "95%" }} className="card-img-top" alt="..." />
        </div>
      </div>

      {/* Team  */}


      <h1 className="pt-5 mt-5 mb-0 text-center text-primary fw-bold">OUR TEAM</h1>

      <div className="row align-items-center justify-content-center  g-0 m-0 mx-3 my-5 pt-5">
        <div className="col-12 col-sm-6 col-md-5 col-lg-4 mb-3">
          <div className="card" style={{ width: "90%", margin: "auto" }}>
            <img src={DrRiaz} style={{ height: 450 }} className="card-img-top" alt="..." />
            <div className="card-body p-4">
              <h5 className="card-title fw-bold text-primary m-0 text-center">Dr.Muhammad Riaz Bhatti</h5>
              <h5 className="card-title fw-bold text-primary m-0 text-center">M.B.B.S (RMP)</h5>
              <h5 className="card-title fw-bold text-primary m-0 text-center">Physician & Surgeon</h5>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-5 col-lg-4 mb-3">
          <div className="card" style={{ width: "90%", margin: "auto" }}>
            <img src={ladydr} style={{ height: 450 }} className="card-img-top" alt="..." />
            <div className="card-body p-4">
              <h5 className="card-title fw-bold text-primary m-0 text-center">Dr.Aqsa Zaman</h5>
              <h5 className="card-title fw-bold text-primary m-0 text-center">M.B.B.S (NMC)</h5>
              <h5 className="card-title fw-bold text-primary m-0 text-center">Physician & Surgeon</h5>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-5 col-lg-4 mb-3">
          <div className="card" style={{ width: "90%", margin: "auto" }}>
            <img src={ladydr} style={{ height: 450 }} className="card-img-top" alt="..." />
            <div className="card-body p-4">
              <h5 className="card-title fw-bold text-primary m-0 text-center">Shumaila Ashraf</h5>
              <h5 className="card-title fw-bold text-primary m-0 text-center">BS Radiology</h5>
              <h5 className="card-title fw-bold text-primary m-0 text-center">Medical Imaging</h5>
            </div>
          </div>
        </div>
      </div>
      {/* <h1 className="pt-5 mt-5 mb-5 text-center text-primary fw-bold">OUR STAFF</h1>
      <ol>
        <h5 className="card-title fs-4 fw-bold text-primary m-0 mb-3 text-center">TANSAR SHARIF : (MID WIFE)</h5>
        <h5 className="card-title fs-4 fw-bold text-primary m-0 mb-3 text-center">ZAINIB : (MID WIFE)</h5>
        <h5 className="card-title fs-4 fw-bold text-primary m-0 mb-3 text-center">FAZILAT : (ATTENDANT)</h5>
        <h5 className="card-title fs-4 fw-bold text-primary m-0 mb-3 text-center">NIDA : (RECEPTION)</h5>
        <h5 className="card-title fs-4 fw-bold text-primary m-0 mb-3 text-center">SHAGUFTA : (ATTENDANT PATIENT)</h5>
        <h5 className="card-title fs-4 fw-bold text-primary m-0 mb-3 text-center">NAEEM : (CLINICAL ASSISTANT)</h5>
        <h5 className="card-title fs-4 fw-bold text-primary m-0 mb-3 text-center">KALEEM : (LAB TECHNITION)</h5>
        <h5 className="card-title fs-4 fw-bold text-primary m-0 mb-3 text-center">ZUBAIR : (STORE ASSISTANT)</h5>
        <h5 className="card-title fs-4 fw-bold text-primary m-0 mb-3 text-center">GHAZANAFAR : (ATTENDANT PATIENT)</h5>
        <h5 className="card-title fs-4 fw-bold text-primary m-0 mb-3 text-center">MUJAHID : (RECEPTION)</h5>
        <h5 className="card-title fs-4 fw-bold text-primary m-0 mb-3 text-center">SUGHRAN : (SWEAPER)</h5>
        <h5 className="card-title fs-4 fw-bold text-primary m-0 mb-3 text-center">MUHAMMAD ALI : (SWEAPER)</h5>
      </ol> */}
    </div>
  )
}

export default AboutComponent