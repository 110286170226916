import React from 'react';
import heroimg1 from '../assets/images/heroimg1.jpg'

const HeroComponent = () => {
  return (
    <div>
      <div className='position-relative'>
        <img src={heroimg1} className='w-100' />
        <div className="d-flex align-items-center justify-content-center flex-column w-100 h-100 position-absolute top-0">
          <h1 className="mb-0 fw-bold fs-1 text-danger">FATIMA HOSPITAL</h1>
          <h1 className="mb-0 fw-bold fs-1 text-danger">PINDI BHATTIAN</h1>
        </div>
      </div>
    </div>
  )
}

export default HeroComponent