import React from 'react'
import fbdp from '../assets/images/fbdp.png'

const FooterComponent = () => {
  return (
    <div className="py-5 px-5 bg-info ">
      <div className="row g-0 m-0 my-5">
        <div className="col-12 col-sm-6 col-md-4 pl-5 pb-3">
          <h3 className="fs-4 fw-bold text-white mb-4">ADDRESS</h3>
          <p className="text-white mb-1 fw-normal fs-6">Lahore Road Pindi Bhattian, District Hafizabad</p>
          <p className="text-white mb-1 fw-normal fs-6"> Punjab Pakistan</p>
          <p className="text-white mb-1 fw-normal fs-6">Phone No: 0300-4854345</p>
          <p className="text-white mb-1 fw-normal fs-6">Ptcl No: 0547-531-346</p>
        </div>

        <div className="col-12 col-sm-6 col-md-4 pl-5 pb-3">
          <h3 className="fs-4 fw-bold text-white mb-4">OPENING HOURS</h3>
          <p className="text-white mb-1 fw-normal fs-6">Monday - Saturday: 7:00am – 7:30pm</p>
          <p className="text-white mb-1 fw-normal fs-6">Sunday: 7:00am – 7:30pm</p>
        </div>

        <div className="col-12 col-sm-6 col-md-4 pr-4 pb-3">
          <h3 className="fs-4 fw-bold text-white mb-4">SOCIAL MEDIA</h3>
          <img src={fbdp} style={{ width: 20, height: 20, marginRight: 10, }} className="card-img-top" alt="..." />
          <a className="text-white mb-1 fw-normal fs-6" href='https://www.facebook.com/muhammadriaz.bhatti.50?mibextid=LQQJ4d' target='_blank'>Dr. Muhammad Riaz Bhatti</a>
        </div>

      </div>
    </div>
  )
}

export default FooterComponent